<template>
  <div class="meal">
    <div class="meal_image">
        <CarouselImage :photos="product.photos" :youtube="product.youtube" />
      <div
        @click="showPrice = !showPrice"
        class="meal_image-price"
        v-if="product.prices.length > 0"
      >
        {{ product.prices[0].price }}{{ $t("s")
        }}<span v-if="morePrice">*</span>
      </div>
    </div>
    <div class="meal_text">
      <div class="meal_text-prices">
        <p class="meal_text-name">
          {{ product.locales[locale]?.name || product.locales["ru"].name }}
        </p>
        <p
          v-if="morePrice"
          style="font-weight: bold"
          @click="showPrice = !showPrice"
        >
          {{ $t("details") }}
        </p>
      </div>
      <div class="prices" v-if="showPrice">
        <p
          class="prices_text"
          v-for="(price, index) in product.prices"
          :key="index"
        >
          ~ {{ price.quantity }} {{ $t("measures." + price.measure) }}
          {{ price.price }}
          {{ $t("som") }}
        </p>
      </div>
      <p class="meal_text-description">
        {{
          product.locales[locale]?.description ||
          product.locales["ru"].description
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CarouselImage from "@/components/CarouselImage"

export default {
  name: "Meals",
  components: {
    CarouselImage,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default() {
        return {
          img: "",
          name: "",
          price: "",
          description: "",
          photos: [],
        };
      },
    },
  },
  data() {
    return {
      showPrice: false,
      CarouselImage,
    };
  },
  computed: {
    ...mapGetters(["locale"]),
    morePrice() {
      return this.product.prices.length > 1;
    },
  },
};
</script>

<style scoped>
.meal {
  padding: 0 10px;
  border-bottom: 1px solid #e0e2e7;
}
.meal_image {
  margin: 0 auto;
  width: 80%;
  height: 400px;
  position: relative;
  margin-top: 10px;
}
.meal_image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.meal_image-price {
  padding: 0 8px;
  min-width: 110px;
  font-weight: bold;
  font-family: SF Pro Display;
  letter-spacing: 1px;
  background-color: rgb(24 24 24 / 57%);
  position: absolute;
  bottom: 0;
  right: 0;
  height: 60px;
  text-align: center;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 10px;
  font-size: 36px;
  color: #ffffff;
  border-top: 5px solid #5f0042;
  overflow: hidden;
}
.meal_text {
  width: 100%;
  overflow-wrap: break-word;
  text-align: start;
}
.meal_text-name {
  font-weight: 700;
  font-size: 22px;
}
.meal_text-description {
  color: #91949d;
}
.meal_text-prices {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prices {
  text-align: end;
}
@media screen and (max-width: 960px) {
  .meal_image {
    width: 100%;
    height: 280px;
  }
}
</style>
