<template>
    <carousel>
      <slide v-for="photo in photos" :key="photo.id">
        <div class="carousel__item"><img class="carousel_img" :src="photo.thumbnail" alt="photo product"></div>
      </slide>
      <slide v-if="youtube" key="youtube">
        <div class="youtube">
          <div @click="actionBtn" class="youtube_btn"></div>
          <YouTube
            style="height: 100%; width: 100%;"
            class="youtube_item"
            :src="youtube"
            ref="youtube" />
        </div>
      </slide>
      <template #addons>
        <Pagination />
      </template>
    </carousel>
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  import { Carousel, Pagination, Slide } from 'vue3-carousel'
  import 'vue3-carousel/dist/carousel.css'
  import YouTube from 'vue3-youtube'
  
  export default defineComponent({
    name: 'CarouselImage',
    components: {
      YouTube,
      Carousel,
      Slide,
      Pagination,
    },
    props: {
      photos: {
        required: true,
      },
      youtube: String,
    },
    methods: {
      actionBtn() {
        if (this.$refs.youtube.player.getPlayerState() == 1) {
          this.$refs.youtube.pauseVideo()
        } else {
          this.$refs.youtube.playVideo()
        }
      },
    },
  })
  </script>
  
  <style>
  .carousel__item {
    height: 280px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .youtube {
    height: 280px;
    width: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: contain;
  }
  .youtube_btn {
    width: 100%;
    height: 100px;
    position: absolute;
    z-index: 1;
  }
  .youtube_item iframe {
    height: 100% !important;
    width: 100% !important;
  }
  
  .carousel__slide {
    padding: 0 5px;
  }
  .carousel_img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
  }
  .carousel__pagination {
    position: absolute;
    bottom: 25px;
    width: 100%;
    
  }
  .carousel__pagination-button::after {
    border-radius: 50% !important;
    width: 7px !important;
    height: 7px !important;
  }
  .carousel__pagination-item {
    background: rgba(255, 255, 255, 0.5);
    
  }
  .carousel__pagination-item:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .carousel__pagination-item:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  </style>